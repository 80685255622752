import {observer} from "mobx-react-lite";
import {ChatsPage} from "./app/ChatsPage";
import {MessagesPage} from "./app/MessagesPage";
import {ContextState, ContextStateExt, ctx, PAGES, PAGES_NAMES} from "./codes/Context";
import React, {useCallback, useEffect, useRef, useState} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {ThemeProvider} from "@emotion/react";
import {appTheme, mesTheme} from "./Theme";
import {autoDefferedProcess, runSocket} from "./network/socket/Sock";
import {InfoPanel, INFOTYPE} from "./app/panels/InfoPanel";
import {Box, Button, Input, Stack, Typography} from "@mui/material";
import {ActionPanel} from "./app/panels/ActionPanel";
import {chatsPool} from "./codes/ChatsPool";
import AppDialog from "./app/panels/AppDialog";
import {IntegrationInstructions} from "@mui/icons-material";
import {dateToMs_String} from "./utils";
declare const window: any;



function FlexBox(pros: {children: React.ReactNode}) {
    return (
        <Box
            sx={{
                width: "100%",
                height: '100dvh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >{pros.children}</Box>
    )
}

const channel = new BroadcastChannel("helpme-tabs-call");

const App = observer(() => {


    const [$doubleCount, $setDoubleCount] = React.useState<boolean>(true);
    const $doubleCountRef = useRef($doubleCount);

    useEffect(() => {
        $doubleCountRef .current = $doubleCount;
      }, [$doubleCount]);

    const [$forceWaite, $setForceWaite] = React.useState<boolean>(false);   

    const [contetState, setContextStateExt] = useState<ContextStateExt>({state: ContextState.LOADING, extratext: []})

    const handlePopState = (event: any) => {

        if ((document.location.pathname === "/" + PAGES_NAMES[0]) && (ctx.currentPage !== PAGES.PAGE_CHATS)) {
            ctx.currentPage = PAGES.PAGE_CHATS;
        }
        if ((document.location.pathname === "/" + PAGES_NAMES[1]) && (ctx.currentPage !== PAGES.PAGE_MESSAGES)) {
            if (chatsPool.Current) {
                ctx.currentPage = PAGES.PAGE_MESSAGES;
            }
            else {
                ctx.currentPage = PAGES.PAGE_CHATS;

            }
        }
    };


    const handleSessionClose =(event: any) => {
        if (event !== undefined)
            event.preventDefault();
        if (!$doubleCountRef.current) {
            ctx.deleteCookie('helmet');
        }
        if (event !== undefined)
            event.returnValue = ''; // Required for Chrome
    }

 
    const handleCloseInterval = () => {

        setTimeout(() => {
            window.location.href = 'about:blank';
            window.close();
        }, 10000);
    }


    const handleLoad= ()=>
    {
        $setDoubleCount(false);
        ctx.saveSessionCookie('helmet', dateToMs_String(new Date()))
        window.addEventListener('beforeunload', handleSessionClose);
        window.history.pushState({page: ""}, 'HelpChat', '/' + PAGES_NAMES[PAGES.PAGE_CHATS]);
        window.addEventListener('popstate', handlePopState);

        setContextStateExt({state: ContextState.LOADING, extratext: []});
        ctx.intitContext(window.callMeToken(), /*window.callMeID(),*/(result: ContextStateExt) => {
            setContextStateExt(result);
            if (result.state === ContextState.READY) {
                runSocket(0);
                autoDefferedProcess();
            }
            if (result.state === ContextState.ERROR) {
                ctx.deleteCookie('helmet');
            }
            if (result.state === ContextState.SECERROR) {
                ctx.deleteCookie('helmet');
            }

        });
    }
  

    useEffect(() => {

        channel.onmessage = (event) => {
            if (event.data === "iam") {
                window.location.href = 'about:blank';
                window.close();
            }
        };

        const helmet = ctx.loadCookieString('helmet', undefined);
        if (helmet !== undefined) {
            $setDoubleCount( true);
        }
        else {
            handleLoad();
        }


        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('beforeunload', handleSessionClose);
            handleSessionClose(undefined);
        };
    }, []);


    return (
        <>

            <React.Fragment>
                <ThemeProvider theme={appTheme}>
                    <CssBaseline enableColorScheme />
                    {(!$doubleCount) ? (
                        <>
                            <Container maxWidth={false} disableGutters sx={{width: '100%', pl: 0.5, pr: 0.5}}>

                                <FlexBox>
                                    {(contetState.state === ContextState.LOADING) && (

                                        <InfoPanel paneltype={INFOTYPE.LOADING} text="Определение пользователя ..." extratext={contetState.extratext} />

                                    )}
                                    {(contetState.state === ContextState.SECERROR) && (

                                        <InfoPanel paneltype={INFOTYPE.ERROR} text="Ошибка определения пользователя, войдите через SFA повторно" extratext={[...contetState.extratext]} />

                                    )}
                                    {(contetState.state === ContextState.ERROR) && (

                                        <InfoPanel paneltype={INFOTYPE.ERROR} text="Ошика определения пользователя" extratext={[...contetState.extratext]} />

                                    )}
                                    {(contetState.state === ContextState.READY) && (
                                        <>
                                            {
                                                ctx.currentPage === PAGES.PAGE_CHATS
                                                    ? (
                                                        <ChatsPage />
                                                    )
                                                    : (
                                                        <ThemeProvider theme={mesTheme}>
                                                            <MessagesPage />
                                                        </ThemeProvider>
                                                    )
                                            }
                                        </>
                                    )}
                                    <AppDialog />
                                    <ActionPanel />
                                </FlexBox>
                            </Container>
                        </>)
                        : (
                            <>
                                <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                                    <Box sx={{
                                        display: 'flex', width: '50%',
                                        alignItems: "center", justifyContent: "center", margin: "auto"
                                    }}>
                                        <Stack direction="column">
                                            {($forceWaite === true)
                                                ? (<>
                                                    <Typography>
                                                        Ждем переключение ...
                                                    </Typography>
                                                </>)
                                                : (<>
                                                    <Typography>
                                                        Диалог открыт в другой вкладке, если это не так, закройте барузер полностью и запустите чат повторно.
                                                    </Typography>
                                                    <Button onClick={() => {
                                                        channel.postMessage("iam");
                                                        $setForceWaite(true);
                                                        setTimeout(
                                                            () => {
                                                                ctx.deleteCookie('helmet');
                                                                handleLoad();
                                                            },
                                                            5000);

                                                    }} color="warning" variant="contained"> Принудительно переключиться сюда</Button>
                                                </>)
                                            }

                                        </Stack>
                                    </Box>
                                </Box>
                            </>
                        )}




                </ThemeProvider>
            </React.Fragment>


        </>
    );
});
export default App;


