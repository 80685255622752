import {observer} from "mobx-react-lite";
import {Box, Stack} from "@mui/material";
import SendingState from "./SendingState";
import {StoredMessage} from "../../codes/DataCord";
import {meBoxCSS, notMeBoxCSS, QuotedBox, WrapTypography, WrapTypographyWithLink} from "./Decorator";
import {Attachments} from "./Attachments";


const MeAgent = observer((props: {message: StoredMessage}) => {
    const {message} = props;
    return (
        <>
            <Box sx={{...meBoxCSS}}>
                <SendingState candidate={message.candidate} />
                <Stack direction="column" alignItems="flex-end">
                    {(message.replymessageid) && (<>
                        <QuotedBox fs={13} sx={{...notMeBoxCSS, pb: 0.4
                        }} alignItems="flex-end">
                            <>{message.replycontent}</>
                        </QuotedBox>
                    </>)}
                    <WrapTypographyWithLink text={message.content}/>
                    <Attachments message={message}/>
                    {/* <Box
                        component="img"
                        src="https://www.slashfilm.com/img/gallery/mary-elizabeth-winstead-cast-in-scott-pilgrim-vs-the-world/intro-import.jpg"
                        alt="Description of the image"
                        sx={{width: '100%', height: 'auto'}} // Responsive styling
                    /> */}
                </Stack>
            </Box>
        </>
    )
});

export {MeAgent}